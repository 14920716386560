const Uwu = () => {
  return (
    <svg
      fill="#000000"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width="16px"
      height="16px"
    >
      <path d="M 8 2 C 4.686 2 2 4.686 2 8 C 2 11.314 4.686 14 8 14 C 11.314 14 14 11.314 14 8 C 14 4.686 11.314 2 8 2 z M 8 3 C 10.757 3 13 5.243 13 8 C 13 10.757 10.757 13 8 13 C 5.243 13 3 10.757 3 8 C 3 5.243 5.243 3 8 3 z M 5.5 6 A 0.5 0.5 0 0 0 5.5 7 A 0.5 0.5 0 0 0 5.5 6 z M 9.5 6 A 0.5 0.5 0 0 0 9.5 7 A 0.5 0.5 0 0 0 9.5 6 z M 4 8 C 4 8.6223241 4.0918666 9.1543666 4.46875 9.53125 C 4.8456334 9.9081334 5.3776759 10 6 10 C 6.609601 10 7.1246562 9.8988795 7.5 9.5410156 C 7.8753438 9.8988795 8.390399 10 9 10 C 9.6223241 10 10.154367 9.9081334 10.53125 9.53125 C 10.908133 9.1543666 11 8.6223241 11 8 L 10 8 C 10 8.5166759 9.9125853 8.7358521 9.8242188 8.8242188 C 9.7358521 8.9125853 9.5166759 9 9 9 C 8.4833241 9 8.2641479 8.9125853 8.1757812 8.8242188 C 8.0874147 8.7358521 8 8.5166759 8 8 L 7 8 C 7 8.5166759 6.9125854 8.7358521 6.8242188 8.8242188 C 6.7358521 8.9125853 6.5166759 9 6 9 C 5.4833241 9 5.2641479 8.9125853 5.1757812 8.8242188 C 5.0874146 8.7358521 5 8.5166759 5 8 L 4 8 z" />
    </svg>
  );
};

export default Uwu;
