const Hashtag = () => {
  return (
    <svg
      fill="#000000"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width="16px"
      height="16px"
    >
      <path d="M 4.996094 2 L 4.996094 4.996094 L 2 4.996094 L 2 5.996094 L 4.996094 5.996094 L 4.996094 10.003906 L 2 10.003906 L 2 11.003906 L 4.996094 11.003906 L 4.996094 14 L 5.996094 14 L 5.996094 11.003906 L 10.003906 11.003906 L 10.003906 14 L 11.003906 14 L 11.003906 11.003906 L 14 11.003906 L 14 10.003906 L 11.003906 10.003906 L 11.003906 5.996094 L 14 5.996094 L 14 4.996094 L 11.003906 4.996094 L 11.003906 2 L 10.003906 2 L 10.003906 4.996094 L 5.996094 4.996094 L 5.996094 2 Z M 5.996094 5.996094 L 10.003906 5.996094 L 10.003906 10.003906 L 5.996094 10.003906 Z" />
    </svg>
  );
};

export default Hashtag;
