const Warning = () => {
  return (
    <svg
      fill="#000000"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width="16px"
      height="16px"
    >
      <path d="M 7.5 1.007813 C 6.957031 1.007813 6.414063 1.265625 6.105469 1.78125 L 0.226563 11.605469 C -0.398438 12.644531 0.402344 14 1.617188 14 L 13.378906 14 C 14.597656 14 15.398438 12.644531 14.777344 11.605469 L 8.894531 1.78125 C 8.585938 1.265625 8.042969 1.007813 7.5 1.007813 Z M 7.5 1.992188 C 7.707031 1.992188 7.914063 2.09375 8.039063 2.296875 L 13.917969 12.117188 C 14.164063 12.527344 13.890625 13 13.378906 13 L 1.617188 13 C 1.109375 13 0.835938 12.527344 1.082031 12.117188 L 6.960938 2.296875 C 7.085938 2.09375 7.292969 1.992188 7.5 1.992188 Z M 6.992188 5 L 6.992188 10 L 7.992188 10 L 7.992188 5 Z M 6.992188 11 L 6.992188 12 L 7.992188 12 L 7.992188 11 Z" />
    </svg>
  );
};

export default Warning;
