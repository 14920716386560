const Team = () => {
  return (
    <svg
      fill="#000000"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width="16px"
      height="16px"
    >
      <path d="M 4.5 3 C 3.121094 3 2 4.121094 2 5.5 C 2 6.445313 2.53125 7.257813 3.304688 7.679688 C 1.40625 8.210938 0 9.933594 0 12 L 1 12 C 1 10.070313 2.570313 8.5 4.5 8.5 C 5.769531 8.5 6.941406 9.203125 7.5625 10.339844 L 8 11.144531 L 8.4375 10.339844 C 9.058594 9.203125 10.230469 8.5 11.5 8.5 C 13.429688 8.5 15 10.070313 15 12 L 16 12 C 16 9.933594 14.59375 8.210938 12.695313 7.679688 C 13.46875 7.257813 14 6.445313 14 5.5 C 14 4.121094 12.878906 3 11.5 3 C 10.121094 3 9 4.121094 9 5.5 C 9 6.4375 9.527344 7.25 10.292969 7.675781 C 9.394531 7.929688 8.589844 8.457031 8 9.195313 C 7.410156 8.457031 6.605469 7.929688 5.707031 7.675781 C 6.472656 7.25 7 6.4375 7 5.5 C 7 4.121094 5.878906 3 4.5 3 Z M 4.5 4 C 5.328125 4 6 4.671875 6 5.5 C 6 6.328125 5.328125 7 4.5 7 C 3.671875 7 3 6.328125 3 5.5 C 3 4.671875 3.671875 4 4.5 4 Z M 11.5 4 C 12.328125 4 13 4.671875 13 5.5 C 13 6.328125 12.328125 7 11.5 7 C 10.671875 7 10 6.328125 10 5.5 C 10 4.671875 10.671875 4 11.5 4 Z" />
    </svg>
  );
};

export default Team;
