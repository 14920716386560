const History = () => {
  return (
    <svg
      fill="#000000"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width="16px"
      height="16px"
    >
      <path d="M 7.5 1 C 5.589844 1 3.878906 1.835938 2.6875 3.152344 L 1 1.464844 L 1 5 L 4.535156 5 L 3.394531 3.859375 C 4.402344 2.722656 5.859375 2 7.5 2 C 10.542969 2 13 4.457031 13 7.5 C 13 10.542969 10.542969 13 7.5 13 C 4.457031 13 2 10.542969 2 7.5 L 2 7 L 1 7 L 1 7.5 C 1 11.085938 3.914063 14 7.5 14 C 11.085938 14 14 11.085938 14 7.5 C 14 3.914063 11.085938 1 7.5 1 Z M 7 3 L 7 8 L 10 8 L 10 7 L 8 7 L 8 3 Z" />
    </svg>
  );
};

export default History;
