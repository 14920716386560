const Spinner = () => {
  return (
    <svg
      fill="#000000"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width="16px"
      height="16px"
    >
      <path d="M 7 1 L 7 4 L 8 4 L 8 1 L 7 1 z M 3.2578125 2.5507812 L 2.5507812 3.2578125 L 4.671875 5.3789062 L 5.3789062 4.671875 L 3.2578125 2.5507812 z M 11.742188 2.5507812 L 9.6210938 4.671875 L 10.328125 5.3789062 L 12.449219 3.2578125 L 11.742188 2.5507812 z M 1 7 L 1 8 L 4 8 L 4 7 L 1 7 z M 11 7 L 11 8 L 14 8 L 14 7 L 11 7 z M 4.671875 9.6210938 L 2.5507812 11.742188 L 3.2578125 12.449219 L 5.3789062 10.328125 L 4.671875 9.6210938 z M 10.328125 9.6210938 L 9.6210938 10.328125 L 11.742188 12.449219 L 12.449219 11.742188 L 10.328125 9.6210938 z M 7 11 L 7 14 L 8 14 L 8 11 L 7 11 z" />
    </svg>
  );
};

export default Spinner;
