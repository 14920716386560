const Burn = () => {
  return (
    <svg
      fill="#000000"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width="16px"
      height="16px"
    >
      <path d="M 7.5 0.96875 L 7.1914062 1.2109375 C 7.1914062 1.2109375 6.1586876 2.0157702 5.125 3.1816406 C 4.0913124 4.347511 3 5.8747392 3 7.5 C 3 9.979258 5.020742 12 7.5 12 C 9.979258 12 12 9.979258 12 7.5 C 12 5.8747392 10.908688 4.347511 9.875 3.1816406 C 8.8413124 2.0157702 7.8085938 1.2109375 7.8085938 1.2109375 L 7.5 0.96875 z M 7.5 2.2929688 C 7.7388101 2.4870869 8.312918 2.9297761 9.125 3.8457031 C 10.091312 4.9355827 11 6.3562608 11 7.5 C 11 8.4858879 10.593199 9.3708653 9.9414062 10.005859 C 9.9784676 9.8353704 10 9.6596859 10 9.4785156 C 10 8.5150269 9.436814 7.5578901 8.9160156 6.7734375 C 8.3952172 5.9889849 7.875 5.3984375 7.875 5.3984375 L 7.4980469 4.96875 L 7.1230469 5.4003906 C 7.1230469 5.4003906 6.6021968 5.9997089 6.0820312 6.7871094 C 5.5618658 7.5745098 5 8.5253351 5 9.4785156 C 5 9.6596859 5.0215324 9.8353704 5.0585938 10.005859 C 4.4068013 9.3708653 4 8.4858879 4 7.5 C 4 6.3562608 4.9086876 4.9355827 5.875 3.8457031 C 6.687082 2.9297761 7.2611899 2.4870868 7.5 2.2929688 z M 7.5019531 6.5585938 C 7.6858427 6.7845943 7.7933803 6.8904026 8.0839844 7.328125 C 8.563186 8.0499224 9 8.9670043 9 9.4785156 C 9 10.313531 8.3350151 10.978516 7.5 10.978516 C 6.6649849 10.978516 6 10.313531 6 9.4785156 C 6 8.9866961 6.4381341 8.0642402 6.9179688 7.3378906 C 7.209945 6.8959115 7.3178537 6.7871559 7.5019531 6.5585938 z M 3.4726562 11.998047 A 0.50005 0.50005 0 0 0 3.3789062 12.984375 L 5.4414062 13.5 L 3.3789062 14.015625 A 0.50005 0.50005 0 1 0 3.6210938 14.984375 L 7.5 14.015625 L 11.378906 14.984375 A 0.50005 0.50005 0 1 0 11.621094 14.015625 L 9.5585938 13.5 L 11.621094 12.984375 A 0.50005 0.50005 0 0 0 11.511719 11.998047 A 0.50005 0.50005 0 0 0 11.378906 12.015625 L 7.5 12.984375 L 3.6210938 12.015625 A 0.50005 0.50005 0 0 0 3.4726562 11.998047 z" />
    </svg>
  );
};

export default Burn;
