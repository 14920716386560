const Fee = () => {
  return (
    <svg
      fill="#000000"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      width="16px"
      height="16px"
    >
      <path d="M 2.5 1 C 1.675781 1 1 1.675781 1 2.5 L 1 12.5 C 1 13.324219 1.675781 14 2.5 14 L 12.5 14 C 13.324219 14 14 13.324219 14 12.5 L 14 2.5 C 14 1.675781 13.324219 1 12.5 1 Z M 2.5 2 L 12.5 2 C 12.78125 2 13 2.21875 13 2.5 L 13 12.5 C 13 12.78125 12.78125 13 12.5 13 L 2.5 13 C 2.21875 13 2 12.78125 2 12.5 L 2 2.5 C 2 2.21875 2.21875 2 2.5 2 Z M 7 4 L 7 4.523438 C 6.613281 4.570313 6.253906 4.730469 5.992188 4.992188 C 5.671875 5.3125 5.5 5.765625 5.5 6.25 C 5.5 6.734375 5.671875 7.1875 5.992188 7.507813 C 6.3125 7.828125 6.765625 8 7.25 8 L 8 8 C 8.257813 8 8.429688 8.078125 8.550781 8.199219 C 8.671875 8.320313 8.75 8.492188 8.75 8.75 C 8.75 9.007813 8.671875 9.179688 8.550781 9.300781 C 8.429688 9.421875 8.257813 9.5 8 9.5 L 7 9.5 C 6.703125 9.5 6.5 9.242188 6.5 8.875 L 6.5 8.75 L 5.5 8.75 L 5.5 8.875 C 5.5 9.695313 6.109375 10.5 7 10.5 L 7 11 L 8 11 L 8 10.5 C 8.484375 10.5 8.9375 10.328125 9.257813 10.007813 C 9.578125 9.6875 9.75 9.234375 9.75 8.75 C 9.75 8.265625 9.578125 7.8125 9.257813 7.492188 C 8.9375 7.171875 8.484375 7 8 7 L 7.25 7 C 6.992188 7 6.820313 6.921875 6.699219 6.800781 C 6.578125 6.679688 6.5 6.507813 6.5 6.25 C 6.5 5.992188 6.578125 5.820313 6.699219 5.699219 C 6.820313 5.578125 6.992188 5.5 7.25 5.5 L 7.875 5.5 C 8.171875 5.5 8.5 5.828125 8.5 6.125 L 8.5 6.25 L 9.5 6.25 L 9.5 6.125 C 9.5 5.277344 8.832031 4.578125 8 4.515625 L 8 4 Z" />
    </svg>
  );
};

export default Fee;
